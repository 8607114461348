import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('settings', [
      'setDrawer',
      'setMiniState',
      'loadConfigurations'
    ])
  },
  computed: {
    ...mapGetters('settings', [
      'getSettings',
      'getDrawer',
      'getMiniState',
      'getChevron',
      'getSecondFooter',
      'getSettingsTable',
      'getBackground',
      'getLogoImage',
      'getSettingsUrls',
      'getBanners',
      'getFaqs',
      'getRegions',
      'getTypeEnte',
      'getTenderStatus',
      'getTenderType',
      'getTenderTypeAnac',
      'getTenderTipoFornitura',
      'getCatPrevalenti',
      'getInfoBandiSource',
      'getTenderResultSource',
      'getInfoBandiProcedura',
      'getTenderResultProcedura',
      'getLanguages',
      'getChatbot',
      'getVideoguide',
      'getDayForExpiringTender',
      'getDayForExpiringAbilitation',
      'getTermsAndConditions',
      'getStatusContratto',
      'getStatusConcorrente',
      'getAnnoAggiornamento'

    ]),
    settingsMiniState: {
      get () {
        return this.getMiniState
      },
      set (value) {
        this.setMiniState(value)
      }
    },
    settingsDrawer: {
      get () {
        return this.getDrawer
      },
      set (value) {
        this.setDrawer(value)
      }
    },
    getIconPrefer: {
      get () {
        return this.getSettingsTable.iconPrefer
      }
    },
    getIconPreferEmpty: {
      get () {
        return this.getSettingsTable.iconPreferEmpty
      }
    },
    getIconPrivacy: {
      get () {
        return this.getSettingsTable.iconPrivacy
      }
    },
    getIconUrl: {
      get () {
        return this.getSettingsTable.iconUrl
      }
    },
    getIconViewAll: {
      get () {
        return this.getSettingsTable.iconViewAll
      }
    },
    getIconDateExpired: {
      get () {
        return this.getSettingsTable.iconDateExpired
      }
    }
  }
}
