import axios from 'axios'
import { url } from '../classes/settingsStatic'

const getDefaultState = () => {
  return {
    settings: {
      drawer: false,
      miniState: false,
      chevron: 'fa-regular fa-angles-left',
      secondFooter: {
        year: new Date().getFullYear(),
        piva: '03553050927',
        company: '<a class="digitalpaText" target="_blank" href="https://www.digitalpa.it"><b>DigitalPA S.r.l.</b></a>'
      },
      table: {
        iconTop: 'fas fa-table',
        iconPrefer: 'fas fa-star',
        iconPreferEmpty: 'far fa-star',
        iconUrl: 'fa-regular fa-arrow-right',
        iconViewAll: 'fas fa-chevron-right',
        iconDateExpired: 'fas fa-exclamation-triangle',
        iconPrivacy: 'fas fa-shield-alt',
        dayForExpiringAbilitation: 30,
        dayForExpiringTender: 1
      },
      url: url,
      logo: {
        globe: 'img:logo/networkpa-logo-globe.svg',
        npa: 'logo/logotipo-networkpa.svg',
        npaReverse: 'logo/logotipo-networkpa-reverse.svg'
      },
      background: 'background/worldwide-connection-blue.jpg',
      banners: [],
      chatbot: {},
      faqs: [],
      regions: [],
      typeEnte: [],
      tenderStatus: [],
      tenderType: [],
      infoBandiSource: [],
      infoBandiProcedura: [],
      tenderResultSource: [],
      tenderResultProcedura: [],
      tenderTypeAnac: [],
      termsAndConditions: [],
      languages: [],
      videoguide: [],
      statusContratto: [],
      annoAggiornamento: [],
      statusConcorrente: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateSettings (state) {
    Object.assign(state, getDefaultState())
  },
  setSettings (state, settings) {
    Object.assign(state.settings, settings)
  },
  setDrawer (state, value) {
    state.settings.drawer = value
  },
  setMiniState (state, value) {
    state.settings.miniState = value
  },
  setChevron (state, value) {
    state.settings.chevron = value
  },
  setBanners (state, value) {
    state.settings.banners = value
  },
  setFaqs (state, value) {
    state.settings.faqs = value
  },
  setRegions (state, value) {
    state.settings.regions = value
  },
  setTypeEnte (state, value) {
    state.settings.typeEnte = value
  },
  setLanguages (state, value) {
    state.settings.languages = value
  },
  setChatbot (state, value) {
    state.settings.chatbot = value
  },
  setVideoguide (state, value) {
    state.settings.videoguide = value
  },
  setTenderStatus (state, value) {
    state.settings.tenderStatus = value
  },
  setTenderType (state, value) {
    state.settings.tenderType = value
  },
  setInfoBandiSource (state, value) {
    state.settings.infoBandiSource = value
  },
  setInfoBandiProcedura (state, value) {
    state.settings.infoBandiProcedura = value
  },
  setTenderResultSource (state, value) {
    state.settings.tenderResultSource = value
  },
  setTenderResultProcedura (state, value) {
    state.settings.tenderResultProcedura = value
  },
  setTenderTypeAnac (state, value) {
    state.settings.tenderTypeAnac = value
  },
  setTenderTipoFornitura (state, value) {
    state.settings.tenderTipoFornitura = value
  },
  setCatPrevalenti (state, value) {
    state.settings.catPrevalenti = value
  },
  setDayForExpiringTender (state, value) {
    state.settings.table.dayForExpiringTender = value
  },
  setDayForExpiringAbilitation (state, value) {
    state.settings.table.dayForExpiringAbilitation = value
  },
  setTermsAndConditions (state, value) {
    state.settings.termsAndConditions = value
  },
  setStatusContratto (state, value) {
    state.settings.statusContratto = value
  },
  setAnnoAggiornamento (state, value) {
    state.settings.annoAggiornamento = value
  },
  setStatusConcorrente (state, value) {
    state.settings.statusConcorrente = value
  }
}

const actions = {
  resetStateSettings ({ commit }) {
    commit('resetStateSettings')
  },
  setDrawer ({ commit, dispatch }, value) {
    commit('setDrawer', value)
  },
  setMiniState ({ commit, dispatch }, value) {
    if (value === true) {
      dispatch('setChevron', 'fa-regular fa-angles-right')
    } else {
      dispatch('setChevron', 'fa-regular fa-angles-left')
    }
    commit('setMiniState', value)
  },
  setChevron ({ commit, dispatch }, value) {
    commit('setChevron', value)
  },
  setConfiguration ({ commit, dispatch }, data) {
    commit('setDayForExpiringTender', data.dayForExpiringTender)
    commit('setDayForExpiringAbilitation', data.dayForExpiringAbilitation)
    commit('setBanners', data.banners)
    commit('setFaqs', data.faq)
    commit('setTenderStatus', data.tenderStatus)
    commit('setTenderType', data.tenderType)
    commit('setInfoBandiSource', data.tenderExternalSource)
    commit('setInfoBandiProcedura', data.tenderExternalProcedura)
    commit('setTenderResultSource', data.tenderResultSource)
    commit('setTenderResultProcedura', data.tenderResultProcedura)
    commit('setTenderTypeAnac', data.tenderTypeAnac)
    commit('setTenderTipoFornitura', data.tenderTipoFornitura)
    commit('setCatPrevalenti', data.catPrevalenti)
    commit('setTypeEnte', data.typeEnte)
    commit('setRegions', data.region)
    commit('setTermsAndConditions', data.terms)
    commit('setVideoguide', data.videoguide)
    commit('setStatusContratto', data.statusContratto)
    commit('setAnnoAggiornamento', data.annoAggiornamento)
    commit('setStatusConcorrente', data.statusConcorrente)
  },
  loadConfigurations ({ commit, state }) {
    return axios.get(state.settings.url.networkpa_configurations)
      .then((response) => {
        commit('setLanguages', response.data.data.languages)
        commit('setChatbot', response.data.data.chatbot)
      })
      .catch(() => {
      })
  }
}

const getters = {
  getSettings: state => {
    return state.settings
  },
  getDrawer: state => {
    return state.settings.drawer
  },
  getMiniState: state => {
    return state.settings.miniState
  },
  getChevron: state => {
    return state.settings.chevron
  },
  getSecondFooter: state => {
    return state.settings.secondFooter
  },
  getBackground: state => {
    return state.settings.background
  },
  getSettingsTable: state => {
    return state.settings.table
  },
  getSettingsUrls: state => {
    return state.settings.url
  },
  getLogoImage: state => {
    return state.settings.logo
  },
  getBanners: state => {
    return state.settings.banners
  },
  getFaqs: state => {
    return state.settings.faqs
  },
  getRegions: state => {
    return state.settings.regions
  },
  getTypeEnte: state => {
    return state.settings.typeEnte
  },
  getLanguages: state => {
    return state.settings.languages
  },
  getChatbot: state => {
    return state.settings.chatbot
  },
  getVideoguide: state => {
    return state.settings.videoguide
  },
  getTenderStatus: state => {
    return state.settings.tenderStatus
  },
  getTenderType: state => {
    return state.settings.tenderType
  },
  getInfoBandiSource: state => {
    return state.settings.infoBandiSource
  },
  getInfoBandiProcedura: state => {
    return state.settings.infoBandiProcedura
  },
  getTenderResultSource: state => {
    return state.settings.tenderResultSource
  },
  getTenderResultProcedura: state => {
    return state.settings.tenderResultProcedura
  },
  getTenderTypeAnac: state => {
    return state.settings.tenderTypeAnac
  },
  getTenderTipoFornitura: state => {
    return state.settings.tenderTipoFornitura
  },
  getCatPrevalenti: state => {
    return state.settings.catPrevalenti
  },
  getDayForExpiringTender: state => {
    return state.settings.table.dayForExpiringTender
  },
  getDayForExpiringAbilitation: state => {
    return state.settings.table.dayForExpiringAbilitation
  },
  getTermsAndConditions: state => {
    return state.settings.termsAndConditions
  },
  getStatusContratto: state => {
    return state.settings.statusContratto
  },
  getAnnoAggiornamento: state => {
    return state.settings.annoAggiornamento
  },
  getStatusConcorrente: state => {
    return state.settings.statusConcorrente
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
